import { Component } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import Swal from "sweetalert2";
import { RequestService } from "../../shared/services/request.service";

@Component({
  selector: "app-footer",
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule],
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
})
export class FooterComponent {
  isSubscribed: boolean = false;
  subscribeForm!: FormGroup;
  contactForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private requestService: RequestService,
    public router: Router
  ) {}

  ngOnInit() {
    this.subscribeForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });

    this.contactForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      name: ["", Validators.required],
      message: ["", [Validators.required]],
    });
  }
  subscribe() {
    if (this.subscribeForm.valid) {
      const formData = new FormData();
      formData.append("email", this.subscribeForm.value.email);
      // this.subscribeService.subscribe(formData).subscribe({
      //   next: (data: any) => {
      //     if (data.status === "error") {
      //       Swal.fire({
      //         title: data.message,
      //         icon: "error",
      //         confirmButtonText: "Dismiss",
      //         confirmButtonColor: "var(--primary-color)",
      //       });
      //       this.isSubscribed = false;
      //       return;
      //     }
      //     this.isSubscribed = true;
      //   },
      //   error: (err) => {
      //     console.log(err);
      //   },
      // });
    } else {
      this.subscribeForm.markAllAsTouched();
      Swal.fire({
        title: "Error!",
        text: "Please enter a valid email address.",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "var(--primary-color)",
      });
    }
  }

  async onContact() {
    const email = this.contactForm.value.email;
    if (!this.validateEmail(email)) {
      Swal.fire({
        title: "Error!",
        text: "Please enter a valid email address.",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "var(--primary-color)",
      });
      return;
    }
    let body = {
      email: email,
      name: this.contactForm.value.name,
      message: this.contactForm.value.message,
    };
    if (body.message.length < 100) {
      Swal.fire({
        title: "Error!",
        text: "Message should be at least 100 characters long.",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "var(--primary-color)",
      });
      return;
    }

    if (this.contactForm.valid) {
      let response = await this.requestService.contact(body);
      Swal.fire({
        title: "Message Sent!",
        text: "We will get back to you soon!",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "var(--primary-color)",
      });
      this.contactForm.reset();
    } else {
      this.contactForm.markAllAsTouched();
    }
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  scrollToBottom() {
    // this.router.navigate(['/']);
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }
}
