<div class="container" id="footer-container"  [class.transparent]="router.url.includes('form')">
  <div class="subscribe-container">
    <div class="content">
      <form class="subscription" [class.done]="isSubscribed" [formGroup]="subscribeForm">
        <input class="add-email" type="email" placeholder="subscribe@me.now" formControlName="email">
        <button class="submit-email" type="button">
          <span class="before-submit" (click)="subscribe()">Subscribe</span>
          <span class="after-submit">Thank you for subscribing!</span>
        </button>
      </form>
    </div>
  </div>

  <footer class="footer"  [class.transparent]="router.url.includes('form')">
    <div class="footer-menu">
      <div class="section-title">
        <h3>Pages</h3>
      </div>
      <ul>
        <li><a [routerLink]="['/services']" (click)="scrollToTop()">Services</a></li>
        <li><a [routerLink]="['/press-release']" (click)="scrollToTop()">Press Release</a></li>
        <li><a [routerLink]="['/blog']" (click)="scrollToTop()">Blog</a></li>
        <li><a href="javascript:void(0)"(click)="scrollToBottom()">Contact</a></li>
        <li><a href="javascript:void(0)" [routerLink]="['/policy/privacy-policy']">Privacy Policy</a></li>
        <li><a href="javascript:void(0)" [routerLink]="['/policy/terms-and-conditions']">Terms & Conditions</a></li>
        <li><a href="javascript:void(0)" [routerLink]="['/policy/cookies-policy']">Cookies Policy</a></li>

      </ul>
    </div>
    <div class="footer-address">
      <div class="section-title">
        <h3>Address</h3>
      </div>
      <p>Marv Tech SRL</p>
      <p>CUI 49778952</p>
      <p>Aleea Constantin Sandu-Aldea,</p>
      <p>Bucharest, Romania</p>
    </div>
    <div class="footer-social">
      <div class="section-title">
        <h3>Socials</h3>
      </div>
      <a href="https://www.instagram.com/marvtech.ai/" class="social-icon">
        <span class="social-icons soc-Instagram"></span>Instagram
      </a>
      <a href="https://www.linkedin.com/company/marvinmarv" class="social-icon">
        <span class="social-icons soc-LinkedIn"></span>LinkedIn
      </a>
      <a href="https://www.facebook.com/profile.php?id=61557944190243" class="social-icon">
        <span class="social-icons soc-Facebook"></span>Facebook
      </a>
      <a href="https://ro.pinterest.com/marvtechai/" class="social-icon">
        <span class="social-icons soc-Pinterest"></span>Pinterest
      </a>
      <a href="https://www.threads.net/@marvtech.ai" class="social-icon">
        <span class="social-icons soc-Threads"></span>Threads
      </a>
      <a href="https://www.tiktok.com/@marvtechai" class="social-icon">
        <span class="social-icons soc-Tiktok"></span>Tiktok
      </a>
    </div>
    <div class="footer-contact-form">
      <div class="section-title">
        <h3>Contact Us</h3>
      </div>
      <form [formGroup]="contactForm">
        <input type="text" placeholder="Your Name" formControlName="name">
        <input type="email" placeholder="Your Email" formControlName="email">
        <textarea placeholder="Your Message" formControlName="message"></textarea>
        <button type="submit" class="primary-btn primary-btn--secondary" (click)="onContact()">Send</button>
      </form>
    </div>
  </footer>
</div>